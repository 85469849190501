
import { Vue, Watch } from "vue-property-decorator"
import Notice from "packs/models/Notice"
import { Logger, funcName, sleep } from "packs/common"

export default class FlashNotice extends Vue {
    Notice = Notice

    // setup() {
    //     const ReactiveNotice = reactive(Notice)

    //     watch(
    //         () => ReactiveNotice.message,
    //         (newVal, oldVal) => {
    //             console.log(`message changed from ${oldVal} to ${newVal}`)
    //         },
    //         { immediate: true }
    //     )

    //     return { ReactiveNotice }
    // }

    mounted() {
        this.updateMessage()
    }

    dismiss() {
        Notice.deleteMessage()
    }

    @Watch("Notice.message", { deep: true })
    updateMessage() {
        Logger(`${funcName()} message:${Notice.message}`)
        if (!Notice || !Notice.message) {
            return
        }

        sleep(1800).then(() => {
            this.nodisp()
        })
    }

    public nodisp() {
        if (!Notice.message) return
        Logger("消します")

        $(".notice").fadeOut(300)
        sleep(300).then(_ => {
            Notice.deleteMessage()
            $(".notice").css({ display: "block" })
        })

        // }, 1800);
    }
}
