
// モジュールを読込.
import { Options, Prop, Vue } from "vue-property-decorator"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, funcName, sleep, zeroPadding } from "packs/common"
import Util from "packs/utils/Util"

// コンポーネントを読込.
import SelectTag from "packs/components/forms/SelectTag.vue"
import FlashNotice from "packs/components/common/FlashNotice.vue"

// モデルを読込.
import DateTag from "packs/models/DateTag"
import Notice from "packs/models/Notice"
import SelectUtil from "packs/utils/SelectUtil"

@Options({
    components: {
        SelectTag,
        FlashNotice,
    },
})
export default class ModalInnerAvailableTime extends Vue {
    @Prop()
    changeOrNew: string // 新規newか変更changeか

    @Prop()
    direct: boolean

    // data
    isSP = isMobile(window.navigator).phone

    currentDateTag: DateTag = null

    changedSomeStatus = false
    week: number[] = null
    startHours = Util.selectHours
    endHours = Util.selectHours
    startTimeStr: string = `8:00`
    endTimeStr: string = `21:00`

    timeArray = SelectUtil.timeArray

    isActive = true

    prefixEndNum = Util.getSec().toString()
    notice = Notice

    // モーダル表示の場合、変更したかと、現在のdtagを通知してモーダルを消してもらいます。
    // @Emit()
    // hide(didChange: boolean, dtag: DateTag) {}

    // モーダルではなくダイレクトに表示している場合、変更した内容を即Dtagに入れて通知します。

    public mounted() {
        this.resetDtag()
    }

    public resetDtag() {
        Logger(`${funcName()}`)
        let dtag = DateTag.createDefaultDtag()
        this.currentDateTag = null
        sleep(100).then(_ => {
            this.currentDateTag = dtag
            this.startTimeStr = `${zeroPadding(dtag.start_hour)}:${zeroPadding(dtag.start_min)}`
            this.endTimeStr = `${zeroPadding(dtag.end_hour)}:${zeroPadding(dtag.end_min)}`
            this.isActive = true
            this.week = dtag.days_of_the_week
        })
    }

    // 曜日のチェック処理.
    public selectCheckbox(e) {
        // const weekNum = e.target.value;
        const weekNum = +e
        let $button = $(`.customWeekButtons .wd${e}`)

        if ($button.hasClass("selected")) {
            Logger("include")
            // $(e.target).removeClass("selected");
            $button.removeClass("selected")
            // this.week = this.week.filter(el => el != weekNum);
            this.week = this.week.filter(el => el != weekNum)
        } else {
            Logger("exclude")
            $button.addClass("selected")
            this.week.push(weekNum)
        }
        this.changedSomeStatus = true
    }

    selectCheckboxHoliday() {
        if (this.currentDateTag.use_holiday) {
            this.currentDateTag.use_holiday = false
        } else {
            this.currentDateTag.use_holiday = true
        }

        this.changedSomeStatus = true
    }

    public selectStartTime(e) {
        Logger(`sttime: ${e}`)
        this.startTimeStr = e
        this.changedSomeStatus = true
    }

    public selectEndTime(e) {
        Logger(`sttime: ${e}`)
        this.endTimeStr = e
        this.changedSomeStatus = true
    }

    // Dtagを追加します。
    public addDtag() {
        Logger(`addDtag start: ${this.startTimeStr} end: ${this.endTimeStr}`)
        if (this.week.length == 0 && !this.currentDateTag?.use_holiday) {
            this.notice.message = `曜日を最低１つ選択してください。`
            return
        }

        let dtag = this.updateCurrentDtag()
        if (!dtag) return

        this.$emit(`update`, dtag)
        nextTick(() => {
            this.resetDtag()
        })
    }

    public updateCurrentDtag(): DateTag {
        let currentDtag = DateTag.createDefaultDtag()
        let timeArr = this.startTimeStr.split(`:`)
        const shour = +timeArr[0]
        const smin = +timeArr[1]

        let endArr = this.endTimeStr.split(`:`)
        const ehour = +endArr[0]
        const emin = +endArr[1]

        if (shour > ehour || (shour == ehour && smin >= emin)) {
            this.notice.message = `可能な時間幅を確認してください。`
            return null
        }

        currentDtag.days_of_the_week = this.week
        currentDtag.is_active = this.isActive
        currentDtag.start_hour = shour
        currentDtag.start_min = smin
        currentDtag.end_hour = ehour
        currentDtag.end_min = emin
        currentDtag.use_holiday = this.currentDateTag.use_holiday
        currentDtag.calendar_tags = this.currentDateTag.calendar_tags
        return currentDtag
    }

    // public activeSwitcher(e) {
    //     let classNames = e.target.getAttribute("class")
    //     if (classNames.includes("checked")) {
    //         this.isActive = true
    //     } else {
    //         this.isActive = false
    //     }
    //     this.changedSomeStatus = true
    // }
}
